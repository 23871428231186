import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { convertCsvToJson, convertJsonToCsv } from 'src/app/helper/helper';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  AsyncValidatorFn,
} from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, map, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-project-modal',
  templateUrl: './create-project-modal.component.html',
  styleUrls: ['./create-project-modal.component.scss'],
})
export class CreateProjectModalComponent {
  @ViewChild('downloadLink') downloadLink!: ElementRef;
  file: File | null = null;
  isDragOver = false;
  isUserListLoaded = false;
  displayUnexpectedError = false;
  message = '';

  // List displayed in dropdown should be in this format
  public userList = [];

  // Selected Project ID from the dropdown
  public selectedUsers = [];

  // List displayed in dropdown should be in this format
  public resourceList: any = [];

  // Selected Project ID from the dropdown
  public selectedResourceList:any;

  // Sample JSON data
  defaultConfigJson: any = [];
  configJson: any = [];

  //info icon text
  infoIconText = {
    projectName:
      'Enter a unique project name that will allow you to manage and differentiate between various brands and indications.',
    projectDesc:
      'Provide a description that outlines the scope of the project, including the brands or indications it covers.',
    projectCode:
      'Enter the 10-digit unique project code used for billing and tracking',
    userList:
      'Select the users to be added to this project. All users will be included by default',
    defaultConfigText:
      'When uploading a new file, ensure it follows the schema of the Default Configuration File. Refer the reference document to have more clarity about the configurations. The Default Configuration will be applied if no file is uploaded.',
    selectUsersText:
      'Choose the users you want to add to the project. By default, all users are selected',
    resource: 'Choose the resources to be consumed for this project. Once a project is created, resources can not be updated',
  };

  ngOnInit() {
    // fetch default config
    this.fetchDefaultConfig();

    //fetch list of users.
    this.fetchUserList();

    //fetch list of resources
    this.fetchResourceList()
  }

  fetchDefaultConfig() {
    //PAC Home endpoint to fetch user details
    const default_config_endpoint = '/configurations/1';
    //GET API call to PAC Home to fetch default config
    this.http.get(default_config_endpoint).subscribe((res: any) => {
      //Setting the default config value
      const reorderData = res.map((obj: any) => {
        const { application, ...rest } = obj;
        return { application, ...rest };
      });
      this.defaultConfigJson = reorderData;
      this.configJson = reorderData;
    });
  }

  fetchUserList() {
    // set isUserListLoaded as false
    this.isUserListLoaded = false;

    // end point to fetch the list of all users
    const endpoint = `/users`;

    //GET API call to fetch user list from
    this.http.get(endpoint).subscribe((res: any) => {
      //Mapping the API result to the format expected in Dropdown
      let loggedUserId = this.modalService.userDetails.user_id;
      loggedUserId.replace('\\', '\\\\');

      if (res.status == 'success') {
        this.userList = res.data
          .filter((u: any) => u.user_id != loggedUserId) // Filter out logged_in user
          .map((u: any) => ({
            label: u.user_name,
            value: u.user_id,
          }));
        console.log(this.userList);
        this.selectedUsers = res.data
          .filter((u: any) => u.user_id != loggedUserId)
          .map((u: any) => u.user_id);
      }
      //set isUserListLoaded as true to hide the loader and show the dropdown
      this.isUserListLoaded = true;
    });
  }

  fetchResourceList() {
    // end point to fetch the list of all resources
    const endpoint = `/resources`;

    //GET API call to fetch resource list
    this.http.get(endpoint).subscribe((res: any) => {
      if (res.status == 'success') {
        this.resourceList = res.data.map((u: any) => ({
          label: u.resource_name,
          value: u.resource_id,
        }));
      }
      //setting selected resource list to be default
      this.selectedResourceList = 1;
    });


  }

  // Form group for project
  public projectForm: FormGroup;

  //Styles for project dropdown
  dropdownStyle = {
    width: '100%',
    'font-size': '16px',
    'font-weight': '400',
    color: '#454250',
    'line-height': '24px',
  };

  handleUserListChange(userId: any) {
    this.selectedUsers = userId;
  }
  handleResourceListChange(resourceId: any) {
    this.selectedResourceList = resourceId;
    console.log(resourceId)
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile = input.files[0];
      if (this.isValidCSVFile(selectedFile)) {
        this.file = selectedFile;
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const csvData = e.target?.result as string;
            const headers = [
              'application',
              'master_instance_type',
              'master_instance_weight',
              'core_instance_type',
              'core_instance_weight',
              'core_on_demand_capacity',
              'task_instance_type',
              'task_instance_weight',
              'task_on_demand_capacity',
              'is_ebs_enabled',
              'core_ebs_size_gb',
              'task_ebs_size_gb',
              'ebs_volume_type',
              'ebs_volume_scaling_factor',
              'spark_configuration_defaults',
              'is_auto_scaling_enabled',
              'auto_scaling_policy',
              'core_market_type',
              'task_market_type',
              'task_spot_capacity',
              'core_spot_capacity',
            ];
            this.configJson = [...convertCsvToJson(csvData, headers)];
            console.log(this.configJson);
          };
          reader.onerror = (error) => {
            console.error('Error reading file:', error);
          };
          reader.readAsText(selectedFile);
        } else {
          console.error('No file selected');
        }
      } else {
        alert('Please upload a valid .csv file.');
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      if (this.isValidCSVFile(droppedFile)) {
        this.file = droppedFile;
        if (droppedFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const csvData = e.target?.result as string;
            const headers = [
              'application',
              'master_instance_type',
              'master_instance_weight',
              'core_instance_type',
              'core_instance_weight',
              'core_on_demand_capacity',
              'task_instance_type',
              'task_instance_weight',
              'task_on_demand_capacity',
              'is_ebs_enabled',
              'core_ebs_size_gb',
              'task_ebs_size_gb',
              'ebs_volume_type',
              'ebs_volume_scaling_factor',
              'spark_configuration_defaults',
              'is_auto_scaling_enabled',
              'auto_scaling_policy',
              'core_market_type',
              'task_market_type',
              'task_spot_capacity',
              'core_spot_capacity',
            ];
            this.configJson = [...convertCsvToJson(csvData, headers)];
            console.log(this.configJson);
          };
          reader.onerror = (error) => {
            console.error('Error reading file:', error);
          };
          reader.readAsText(droppedFile);
        } else {
          console.error('No file selected');
        }
      } else {
        alert('Please upload a valid .csv file.');
      }
    }
  }

  removeFile(): void {
    this.file = null;
    this.configJson = this.defaultConfigJson;
  }

  private isValidCSVFile(file: File): boolean {
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  }

  downloadDefaultConfiguration() {
    const csvData = convertJsonToCsv(this.defaultConfigJson);

    // Create a blob of the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create an object URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Set the href attribute of the download link to the object URL
    const downloadLink = this.downloadLink.nativeElement;
    downloadLink.href = url;
    downloadLink.download = 'defaultConfig.csv';

    // Release the object URL after the download is initiated
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
  }

  constructor(
    public modalService: ModalService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.projectForm = this.fb.group({
      projectName: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/),
        ],
        [this.projectNameUniqueValidator(this.http)],
      ],
      projectDescription: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      projectCode: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{10}$/)],
      ],
    });
  }

  projectNameUniqueValidator(http: HttpClient): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null); // Return null if there's no value to check
      }

      const endpoint = `check_project_name_exists?project_name=${control.value}`;
      return http.get(endpoint).pipe(
        debounceTime(300),
        map((res: any) => {
          // Assuming res.status is 'success' and res.data indicates if the name exists
          return res.status === 'success' && res.data
            ? { projectNameTaken: true }
            : null;
        }),
        catchError(() => of(null)) // Handle errors and consider the name as unique
      );
    };
  }

  handleBackButton(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.modalService.isProjectSelected) {
      this.modalService.openSelectProjectModal();
    }
    this.modalService.closeCreateProjectModal();
  }

  @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent) {
    // Your logic for handling the Esc key press
    console.log("Escape key pressed");
    this.handleBackButton(event); // Example: Close a modal
    }

  saveProjectDetails() {
    //Data Explorer end point to save project details
    const endpoint = `projects`;
    const payload = {
      project_name: this.projectForm.value.projectName,
      project_desc: this.projectForm.value.projectDescription,
      project_code: this.projectForm.value.projectCode,
      resource_id:this.selectedResourceList,
      user_list: this.selectedUsers,
      config: this.configJson,
    };
    console.log('Payload', payload);
    this.http.post(endpoint, payload).subscribe({
      next: (res: any) => {
        this.message = 'Project created successfully';
        if (!this.modalService.isProjectSelected) {
          this.modalService.openSelectProjectModal();
        }
        this.modalService.closeCreateProjectModal();
        window.location.reload();
      },
      error: (err) => {
        this.message = '';
        this.displayUnexpectedError = true;
        console.log(err);
      },
    });
  }

  handleSubmit() {
    if (this.projectForm.valid) {
      console.log('Form Submitted', this.projectForm.value);
      console.log('config', this.configJson);
      console.log('Users', this.selectedUsers);
      console.log('curent user', this.modalService.userDetails);
      this.message = 'Project is being created...';
      this.saveProjectDetails();
    } else {
      this.projectForm.markAllAsTouched();
    }
    //refresh page after submit is successful
  }
}
